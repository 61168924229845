import React from 'react';

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="hero-wrap" style={{backgroundImage: "url('images/bg_1.jpg')"}} data-stellar-background-ratio="0.5">
      <div className="overlay"></div>
      <div className="overlay-2"></div>
      <div className="container">
        <div className="row no-gutters slider-text align-items-center">
          <div className="col-md-7 d-flex align-items-end">
            <div className="text w-100" style={{"fontFamily": "'Roboto', sans-serif"}}>
              <h1 className="mb-4">Bitikommerce</h1>
              <h2>Comming Soon, stay tuned!</h2>
              <p className="mb-4">
                A big impact on e-commerce platforms is on its' way!
                <br/>
                We are bulding a platform for everyone, we are bringing higher values!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}


export default App;
